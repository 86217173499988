
$( document ).ready(function() {

    $('#link_identif').click(function(){
        var elem = document.getElementById('identification');
        if  (elem.style.display == 'none'){
            elem.style.display = 'block';
            em.style.overflow='inherit';
        }
        return false;
    });

});

